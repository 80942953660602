import React, { useState, useContext } from "react"
import logo from "../../assets/inspirits_red.svg"
import mobilelogo from "../../assets/mob_top_logo.svg"
import "./menu.css"
import { BsLinkedin } from "react-icons/bs"
import { BiLogoInstagramAlt } from "react-icons/bi"
import LanguageContext from "../../helpers/context"
import translate from "../../helpers/translate"
import { Link } from "react-router-dom";

export default function Menu() {

    const [open, setOpen] = useState(false)
    const [mobileopen, setMobOpen] = useState(false)
    const { language, setLanguage } = useContext(LanguageContext)

    const option = () => {
        setOpen(current => !current)
    }

    const check = (e) => {
        let value = e.target.value
        switch (value) {
            case 1:
                setLanguage("LV")
                break;
            case 2:
                setLanguage("EN")
                break;
            default:
                setLanguage("LV")
        }
        setOpen(false)
    }

    const mobilemenu = (e) => {
        setMobOpen(current => !current)
    }

    const closemenu = (e) => {
        setMobOpen(false)
    }

    return (

        <nav>
            <span className="soc">
                <a href="/#home" title="Home" >
                    <picture>
                            <source media="(max-width: 1200px)" srcSet={mobilelogo} width="42px"/>
                                <img src={logo} alt="Logo" title="Inspirits logo"/>
                            </picture>
                        </a>
                        <a href="https://www.linkedin.com/company/inspirits/about/" target="_blank" rel="noreferrer" title="Link to Linkedin profile" ><BsLinkedin size={40} /></a>
                        <a href="https://www.instagram.com/_inspirits/?igshid=Yjc0OGI0MDc4OA%3D%3D" title="Link to Instagram profile" target="_blank" rel="noreferrer" ><BiLogoInstagramAlt size={55} /></a>
                    </span>
                    <div>
                        <div className={mobileopen ? "close" : "mobile"} onClick={mobilemenu}
                        >
                            <span className="line"></span>
                            <span className="line"></span>
                            <span className="line"></span>
                        </div>

                        <ul className={mobileopen ? "mobilelist" : ""}>
                            <li><Link to="/news" title="News" onClick={closemenu}>{translate(language, "menu-news")}</Link></li>
                            <li><Link to="/#home" title="Home" onClick={closemenu}>{translate(language, "menu-home")}</Link></li>
                            <li><Link to="/#about" title="About" onClick={closemenu}>{translate(language, "menu-par")}</Link></li>
                            <li><Link to="/#categories" title="Categories" onClick={closemenu}>{translate(language, "menu-cat")}</Link></li>
                            <li><Link to="https://www.spiritsandwine.lv/en/" title="Shop" target="_blank" onClick={closemenu}>{translate(language, "menu-buy")}</Link></li>
                            <li><Link to="/#contact" title="Contacts" onClick={closemenu}>{translate(language, "menu-contacts")}</Link></li>
                        </ul>

                        <div className="dropdown" onClick={option}>{language}<i className={open ? "arrow up" : "arrow down"} />
                        </div>
                        {(open || mobileopen) && <div className="options" >
                            <ol>
                                <li className={language === "LV" ? "selected" : ""} value={1} onClick={check}>LV</li>
                                <li className={language === "EN" ? "selected" : ""} value={2} onClick={check}>EN</li>
                            </ol>
                        </div>
                        }
                    </div>
                </nav>

                )
}