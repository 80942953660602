import React, { useContext } from "react"
import "./home.css"

import LanguageContext from "../../helpers/context"
import translate from "../../helpers/translate"
import Partners from "../partners/partners"

export default function Home() {
    const { language } = useContext(LanguageContext)

    return (
        <section id="home" className="home">
            <div className="flex">
                <h1>{translate(language, "home-title")}</h1>
                <p>{translate(language, "home-par")}</p>
            </div>
            <span>
            <Partners/>
            </span>
        </section>
    )
}