import React, { useContext, useEffect, useState } from "react"

import "./contacts.css"
import translate from "../../helpers/translate"
import LanguageContext from "../../helpers/context"

import dati from "../../helpers/dati.json"


export default function Contacts() {
    const { language } = useContext(LanguageContext)
    const [data, setData] = useState([])

    useEffect(() => {
        const object = dati.partners
        setData(object)
    }, [])

    return (
        <section id="contact" className="contacts">
            <h1>{translate(language, "contact-us")}</h1>
            <p>
                Jānis Lauris<br />
                janis.lauris@inspirits.lv<br />
                M. +37129357888<br />
                Terbatas street 33-20,<br />
                Riga, Latvia, LV-1011
            </p>
          

            <span>
                <h3>{translate(language, "sub-partners")}</h3>
                {data.map(x=> <a key={x.id} href={x.href} title={x.alt} target="_blank" rel="noreferrer" ><img src={x.url} alt={x.alt} title={x.alt} width="150px" height="100%"/></a>)}
            </span>
        </section>
    )
}