import React from "react"
import "./partners.css"
import dati from "../../helpers/dati.json"

export default function Partners() {

    return (

        <div className="partners">
            {dati.images.map(x => <a href={x.url} title={x.alt} target="_blank" rel="noreferrer" key={x.src}><img key={x.src} src={x.src} alt={x.alt} title={x.alt} width="70px" height="80%" /></a>)}
        </div>

    )
}