import React from "react"
import Home from "../home/home"
import About from "../about/about"
import Contacts from "../contact/contacts"
import Categories from "../categories/categories"
import Menu from "../menu/menu"
import ScrollToHashElement from "../../helpers/scrollTohashElement"

export default function Main(){

    return(
        <>
        <Menu/>
        <ScrollToHashElement />
        <Home/>
        <About/>
        <Categories/>
        <Contacts/>
        </>
    )
}