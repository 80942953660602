import React, { useContext, useEffect, useState } from "react"
import "./catrender.css"
import LanguageContext from "../../../helpers/context"
import translate from "../../../helpers/translate"
import { useParams } from 'react-router-dom';
import dati from "../../../helpers/dati.json"
import Menu from "../../menu/menu";
import NotFound from "../../notfound/notfound"
import { Link } from "react-router-dom";

export default function Catrender() {
    window.scrollTo(0, 0)

    const { language } = useContext(LanguageContext)
    const [data, setData] = useState([])
    const [currentIndex, setCurrentIndex] = useState(0);

    const params = useParams()
    const { title } = params
    const MyRef = React.createRef();

    useEffect(() => {
        const object = dati.categories.filter(x => x.name === title)

        setData(object)
        setCurrentIndex(0)
        scrollTo();
    }, [title])


    const scrollTo = () => {
        window.scrollTo({
            top:MyRef.offsetTop, 
            behavior: "smooth" // smooth scroll.
        });   
    }
    
    const minusSlides = (e) => {

        if (currentIndex === data[0].photos.length - 1) {
            setCurrentIndex(0);
        } else {
            setCurrentIndex(currentIndex + 1);
        }
    }

    const plusSlides = (e) => {

        if (currentIndex === 0) {
            setCurrentIndex(data[0].photos?.length - 1);
        } else {
            setCurrentIndex(currentIndex - 1);
        }
    }


    if (data.length === 0) {
        return <NotFound />
    }

    document.addEventListener('touchstart', handleTouchStart, false);
    document.addEventListener('touchmove', handleTouchMove, false);

    var xDown = null;
    var yDown = null;

    function getTouches(evt) {
        return evt.touches ||             // browser API
            evt.originalEvent.touches; // jQuery
    }

    function handleTouchStart(evt) {
        const firstTouch = getTouches(evt)[0];
        xDown = firstTouch.clientX;
        yDown = firstTouch.clientY;
    };

    function handleTouchMove(evt) {
        if (!xDown || !yDown) {
            return;
        }

        var xUp = evt.touches[0].clientX;
        var yUp = evt.touches[0].clientY;

        var xDiff = xDown - xUp;
        var yDiff = yDown - yUp;

        if (Math.abs(xDiff) > Math.abs(yDiff)) {/*most significant*/
            if (xDiff > 0) {
                /* right swipe */
                if (currentIndex + 1 !== data[0].photos.length) {
                    minusSlides()
                }
                const note = document.querySelector('.swipe');
                if (note) {
                    note.style.display = "none"
                }
            } else {
                /* left swipe */
                if (currentIndex !== 0) {
                    plusSlides()
                }
                const note = document.querySelector('.swipe');
                if (note) {
                    note.style.display = "none"
                }
            }
        }
        /* reset values */
        xDown = null;
        yDown = null;
    };

    return (
        <>
            <Menu />
            <section className="catrender">

                <h1>{translate(language, title)}</h1>
                <span>
                    <ul>
                        {dati.categories.map(x => <Link key={x.name} to={`/${x.name}`} title={x.name} className={x.name === title ? "link selected" : "link"}><li key={x.name}>{translate(language, x.name)}</li></Link>)}
                    </ul>
                </span>
                <div className="slideshow-container">
                    <div className="product"><img title={data[currentIndex]?.alt} src={data[0]?.photos[currentIndex]?.url} alt={data[currentIndex]?.alt} width="auto" height="100%" /></div>
                    <div className="mobilecount">{currentIndex + 1 + "/" + data[0]?.photos.length}</div>
                    <div className="text">
                        <h3>{data[0]?.photos[currentIndex]?.title}</h3>
                        <p>{translate(language, data[0]?.photos[currentIndex]?.text)}</p>
                        {data[0]?.photos[currentIndex]?.buy && <a href={data[0]?.photos[currentIndex]?.buy} title={"Link to spirits e-shop"} target="_blank" rel="noreferrer" >
                            <button>{translate(language, "buy")}</button>
                        </a>}
                    </div>
                    <div className={currentIndex === 0 ? "noshow" : "prev"} onClick={plusSlides} />
                    <div className={currentIndex + 1 === data[0].photos.length ? "noshow" : "next"} onClick={minusSlides} />
                    {data[0].photos.length > 1 && <div className="swipe" >&#9759;</div>}
                </div>
            </section>
        </>
    )
}