import React, {useContext} from "react"
import "./categories.css"
import dati from "../../helpers/dati.json"
import LanguageContext from "../../helpers/context"
import translate from "../../helpers/translate"
import { Link } from "react-router-dom";
import { ScrollRestoration } from "react-router-dom";

export default function Categories() {
    const { language } = useContext(LanguageContext)

    return (
        <section id="categories" className="categories">
      <ScrollRestoration />

            <div className="half">
                <span>
                    <h1>{translate(language, "categories")}</h1>
                    <ul>
                        {dati.categories.map(x =>  <Link key={x.name} to={`/${x.name}`} className="link" title={x.name}><li key={x.name}>{translate(language, x.name)}</li></Link>)}
                    </ul>
                </span>
                <p>{translate(language, "cat-text")}</p>
            </div>
        </section>
    )
}