import React, { useState, useEffect } from "react"
import { useCookies } from 'react-cookie';
import "../agreement/agreement.css"

export default function Agreement() {
    const [open, setopen] = useState(true)
    const [cookies, setCookie] = useCookies(['consent']);

    useEffect(() => {
        if (cookies.consent) {
            setopen(false)
        }
        else { setopen(true) }
    }, [cookies.consent])

    const consent = (e) => {
        e.preventDefault()
        setopen(false)
        setCookie("consent", true)
    }
    
    return (
        <>
            {open &&
                <section className="agree">
                    <div className="wrapper" >
                        Are you legal age of your country ?
                        <span className="age"><button className="approve" onClick={consent}>Yes</button><button className="cancel">No</button></span>
                    </div>
                </section>
            }
        </>
    )
}