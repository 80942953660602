import React, { useContext } from "react"
import LanguageContext from "../../helpers/context"
import translate from "../../helpers/translate"
import passion from "../../assets/passion2.jpeg"
import experience from "../../assets/pieredze.jpeg"
import performance from "../../assets/izpildijums.jpeg"

import "./about.css"

export default function About() {
    const { language } = useContext(LanguageContext)

    return (
        <section id="about" className="about">
            <h1>{translate(language, "about-title")}</h1>
            <p>{translate(language, "about-text")}</p>
            <div className="properties">
                <div>
                    <h3>{translate(language, "passion")}</h3>
                    <img src={passion} alt="passion" width="200px" height="200px" title="Passion for industry"/>
                </div>
                <div>
                    <h3>{translate(language, "experience")}</h3>
                    <img src={experience} alt="passion" width="200px" height="200px" title="Experience in industry"/>

                </div>
                <div>
                    <h3>{translate(language, "performance")}</h3>
                    <img src={performance} alt="passion" width="200px" height="200px" title="Performance"/>

                </div>
            </div>
        </section>
    )
}