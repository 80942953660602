import React from "react"
import "./footer.css"

export default function Footer(){
    return(
<footer>
<p>All rights reserved by inspirits.lv</p>
<p>Created by <a href="https://frogit.lv" title="Frogit WEB solutions">frogit.lv</a></p>
<p>2023</p>
</footer>
    )
}