import React, { useContext, useEffect, useState } from "react"
import "./news.css"
import LanguageContext from "../../helpers/context"
import translate from "../../helpers/translate"
import dati from "../../helpers/dati.json"
import Menu from "../menu/menu";
import NotFound from "../notfound/notfound"

export default function News() {
    window.scrollTo(0, 0)

    const { language } = useContext(LanguageContext)
    const [data, setData] = useState([])
    const [selected, setselected] = useState({})
    const [width,setWidth] = useState(0); 
 
    useEffect(() => {
        window.addEventListener('resize', handleResize);
        const object = dati.news
        setData(object)
        setselected(object[0])

    }, [])

    const handleResize = () => {
        setWidth(window.innerWidth);
      };
      

    if (data.length === 0) {
        return <NotFound />
    }

    const fileterd = data.filter((value, index) => index < 3)

    const readnews = (item) => {
        setselected(item)
    }
   // const isMobile = /iPhone|iPad|iPod|Android|Tablet|iPad/i.test(navigator.userAgent);
    return (
        <>
            <Menu />
            <section className="news">

                <div className="row">

                    <div className="news-product">
                        <img title={selected.alt} src={selected.url} alt={selected.alt} width="auto" height="100%" /></div>
                    <div className="text">
                    <h1>{translate(language, "news")}</h1>

                        <h3>{translate(language, selected.title)}</h3>
                        <p dangerouslySetInnerHTML={{ __html: translate(language, selected.text) }}></p>
                    </div>

                    <div className="latest">
                        <h3>{translate(language, "other-news")}</h3>
                        {fileterd.map(x =>
                            <span key={x.id} onClick={() => readnews(x)}>
                                <img title={x.text} src={x.url} alt={x.text} width="100px" />
                                <h4>{translate(language, x.title)}</h4>
                            </span>
                        )}
                    </div>
                </div>
            </section>
        </>
    )
}