import React, {useContext} from "react"

import "./notfound.css"
import Menu from "../menu/menu"
import LanguageContext from "../../helpers/context"
import translate from "../../helpers/translate"

export default function NotFound(){
    const { language } = useContext(LanguageContext)
    return(
        <>
        <Menu/>
        <section className="notfound">
            <h1>{translate(language, "404")}</h1>
        </section>
        </>
    )
}