import React, { useState } from "react"
import "./App.css"
import "./fonts/Century Gothic.ttf"
import Agreement from "./components/agreement/agreement"
import { CookiesProvider } from 'react-cookie';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

import NotFound from "./components/notfound/notfound";
import LanguageContext from "./helpers/context"
import Main from "./components/main/main";
import Footer from "./components/footer/footer";
import Catrender from "./components/categories/catrender/catrender";
import News from "./components/news/news"

export default function App() {
  const [language, setLanguage] = useState("LV")

  const router = createBrowserRouter([
    {
      path: "*",
      element: (<NotFound />)
    },
    {
      path: "/",
      errorElement: <NotFound />,
      element: (<Main language={language} />),
    },
    {
      path: "/:title",
      errorElement: <NotFound />,
      element: <Catrender props={":title"} />

    },
    {
      path: "/News",
      errorElement: <NotFound />,
      element: (<News/>),
    },

  ])


  return (
    <CookiesProvider defaultSetCookies={{ path: '/' }}>
      <LanguageContext.Provider value={{ language, setLanguage }}>
        <RouterProvider router={router} />
        <Agreement />
        <Footer />
      </LanguageContext.Provider>
    </CookiesProvider>
  )
}